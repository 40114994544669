<template>
  <section class="store-balance-wrapper">
    <search @search="handleSearch" @reset="handleRest" />
    <div class="store-table-wrapper">
      <vxe-grid v-loading="loading" border :data="dataSource" :columns="tableColumn" size="small">
        <!-- v-bind="tableOptions" -->
        <!-- <template #toolbar_buttons>
          <el-button size="small" type="default">导出 </el-button>
        </template> -->
        <template #header_selletAmount>
          <div>
            <span>冻结中金额</span>
            <el-tooltip class="item" effect="dark" content="分账后金额会暂时冻结，第2天10点后才能提现" placement="top-start">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
        </template>
        <template #selletAmount="{ row }">
          <span style="color: #8080ff"> ¥{{ row.selletAmount }} </span>
        </template>
        <template #unWithdrawAmount="{ row }">
          <span :underline="false" style="color: #f59a23"> ¥{{ row.unWithdrawAmount }} </span>
        </template>
        <template #unAuditWithdrawAmount="{ row }">
          <el-link :underline="false" style="color: #02a7f0" @click="go('/withdrawal/list', row.storeId, 1)"> ¥{{ row.unAuditWithdrawAmount }} </el-link>
        </template>
        <template #unPayerAmount="{ row }">
          <el-link :underline="false" style="color: #8080ff" @click="go('/withdrawal/list', row.storeId, 2)"> ¥{{ row.unPayerAmount }} </el-link>
        </template>
        <template #totalAmount="{ row }">
          <span> ¥{{ row.totalAmount }} </span>
        </template>
        <template #unConfirmAmount="{ row }">
          <el-link :underline="false" @click="go('/split/list', row.storeId, '0')"> ¥{{ row.unConfirmAmount }} </el-link>
        </template>

        <template #payedAmount="{ row }">
          <el-link :underline="false" @click="go('/withdrawal/list', row.storeId, 3)"> ¥{{ row.payedAmount }} </el-link>
        </template>
        <template #pager>
          <i-pagination
            :page-size="iPagination.pageSize"
            :current-page="iPagination.currentPage"
            :page-sizes="[10, 20, 30, 40]"
            layout="total,prev, pager, next ,sizes,jumper"
            :total="iPagination.total"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
          />
        </template>
      </vxe-grid>
    </div>
  </section>
</template>

<script>
// 供应商余额
import Search from './components/search/index.vue'
import IPagination from '@/components/i-pagination/index.vue'
import TableListMixin from '@/minixs/TableListMinix'

export default {
  name: 'StoreBalance',
  components: {
    Search,
    IPagination,
  },
  mixins: [TableListMixin],
  data() {
    return {
      iPagination: {
        pageSize: 10,
        currentPage: 1,
      },
      url: {
        list: 'supplier-web-api/admin/storewallet/getList',
      },
      tableColumn: [
        // { type: 'checkbox', minWidth: 50 },
        { field: 'storeId', title: '供应商ID', minWidth: 150 },
        { field: 'storeName', title: '供应商名称', minWidth: 150 },
        {
          field: 'unWithdrawAmount',
          title: '可提现金额',
          minWidth: 150,
          slots: { default: 'unWithdrawAmount' },
        },
        {
          field: 'selletAmount',
          title: '冻结中金额',
          minWidth: 150,
          slots: { default: 'selletAmount', header: 'header_selletAmount' },
        },
        {
          field: 'unAuditWithdrawAmount',
          title: '提现审核中金额',
          minWidth: 150,
          slots: { default: 'unAuditWithdrawAmount' },
        },
        {
          field: 'unPayerAmount',
          title: '待打款金额 ',
          minWidth: 150,
          slots: { default: 'unPayerAmount' },
        },
        {
          field: 'totalAmount',
          title: '已确认分账金额 ',
          minWidth: 150,
          slots: { default: 'totalAmount' },
        },
        {
          field: 'unConfirmAmount',
          title: '待确认分账金额 ',
          minWidth: 150,
          slots: { default: 'unConfirmAmount' },
        },
        {
          field: 'payedAmount',
          title: '已打款金额 ',
          minWidth: 150,
          slots: { default: 'payedAmount' },
        },
      ],
      tableOptions: {
        toolbarConfig: {
          custom: true,
          slots: {
            // 自定义工具栏模板
            buttons: 'toolbar_buttons',
            header: 'name_header',
          },
        },
      },
    }
  },
  methods: {
    go(url, storeId, state) {
      const data = {
        storeId,
      }
      if (url === '/split/list') {
        data.withdrawState = state
      } else {
        data.state = state
      }

      this.$router.push({
        path: url,
        query: data,
      })
    },
  },
}
</script>

<style lang="less" scoped>
.store-balance-wrapper {
  .store-table-wrapper {
    background: #fff;
    padding: 0 20px;
  }
}
</style>
