<template>
  <div class="search">
    <el-form :model="form" class="demo-form-inline" size="small" inline>
      <div class="top">
        <el-form-item label="供应商ID：" class="form-item" prop="storeId">
          <el-input v-model="form.storeId" placeholder="请输入" class="form-item-input"></el-input>
        </el-form-item>
        <el-form-item label="供应商名称：" class="form-item" prop="storeName">
          <el-input
            v-model="form.storeName"
            placeholder="请输入"
            class="form-item-input"
          ></el-input>
        </el-form-item>
        <el-form-item class="form-item" label-width="30px">
          <el-button icon="el-icon-search" type="primary" size="small" @click="search"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh-left" size="small" @click="reset">重置</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  components: {},
  data() {
    return {
      form: {
        storeId: '',
        storeName: ''
      },
      fold: false,
      tags: [{ name: '标签一' }, { name: '标签二' }]
    };
  },
  methods: {
    search() {
      this.$emit('search', this.form);
    },
    reset() {
      this.form.storeName = '';
      this.form.storeId = '';
      this.$emit('reset', this.form);
    }
  }
};
</script>
<style lang="less" scoped>
@import './search.less';
</style>
